import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { color, breakpoints } from "../../utils/style"
import IcomoonIcon from "../../components/shared/IcomoonIcon"

const El = styled("button")`

   -webkit-appearance: none;
   display:inline-block;
   color: ${color.white};
   font-size: 1rem;
   float: right;
   span {
     margin-right: 10px;
   } 
   
   @media (max-width: ${breakpoints.md}) {
    display:inline-block;
    color: ${color.white};
    font-size: 1rem;
   }

`

const OpenSubsLink = (props) => {
  return (
    <El className="button black-button" aria-label="Open Subcategories" onClick={props.handlerFunction}>
      <span>{props.title}</span>
      <IcomoonIcon icon="arrow-down-simple" color={color.trueBlack} size={16} />
    </El>
  )
}

export default OpenSubsLink

OpenSubsLink.propTypes = {
  handlerFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}