import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import { color, breakpoints } from "../../utils/style"
import OpenSubsLink from "./openSubLink"

const Wrapper = styled("div")`
  position: relative;
  height: 50px;
  margin: -20px 0 0;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    margin: -20px 0;
    .subcats-menu {
      float: none;
    }
  }
`

const SubsUl = styled("ul")`
   clear: both;
   border: 2px solid ${color.transparentWhite2};
   background: ${color.trueBlack};
   border-radius: 10px;
   position: absolute;
   top: 50px;
   right: 0;
   width: fit-content;
   z-index: 2;
   padding: 20px;
   height: 0;
   transition: height 0.5s ease;
   opacity: 0;
   li {
     display: none;
   }
   &.open {
     height: auto;
     opacity: 1;
     li {
       display: inline-block;
     }
   }
  @media (max-width: ${breakpoints.md}) {
    &.open {
      li {
        display: block;
      }
    }
  }
`

const handleButtonClick = e => {
  const subcatsList = document.getElementById("subcats-ul")
  if (subcatsList) {
    subcatsList.classList.toggle("open")
  }
}

const SubLi = styled("li")`
  width: 18%;
  display: inline-block;
  vertical-align: top;
  margin: 0.66%;
  .active {
    color: ${color.darkOrange};
  }
  @media (max-width: ${breakpoints.md}) {
    width: 47.5%;
    margin: 1%;
    a {
      display: block;
      padding: 10px 0;
    }
  }

`

const Subcategories = (props) => (
  <StaticQuery
    query={graphql`{
      allWordpressCategory(filter: {parent_element: {slug: {eq: "blog"}}}) {
        edges {
          node {
            path
            name
            wordpress_id
          }
        }
      }
    }`}
    render={data => (
      <Wrapper>
        <OpenSubsLink title={props.title} handlerFunction={e => handleButtonClick(e)} />
        <SubsUl id="subcats-ul">
          {data.allWordpressCategory.edges.map((item) =>
            <SubLi key={item.node.wordpress_id}>
              <Link to={item.node.path.replace("category/", "")} activeClassName="active">{item.node.name}</Link>
            </SubLi>
          )}</SubsUl>
      </Wrapper>
    )}
  />
)

export default Subcategories

Subcategories.propTypes = {
  title: PropTypes.string.isRequired
}
