import React from "react"

const PromotionPack = ({ width = "72", height = "73", viewBox = "0 0 72 73" }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <filter id="eekivb0cla" width="162.2%" height="196.8%" x="-31.1%" y="-43.3%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                       values="0 0 0 0 1 0 0 0 0 0.320257867 0 0 0 0 0 0 0 0 1 0" />
        <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1" />
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2"
                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter id="scspvc892c" width="149.1%" height="176.4%" x="-24.6%" y="-33.1%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1" />
        <feOffset dx="1" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic"
                     result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" result="shadowMatrixInner1"
                       values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation="1" />
        <feOffset dx="-1" dy="-1" in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite in="shadowOffsetInner2" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic"
                     result="shadowInnerInner2" />
        <feColorMatrix in="shadowInnerInner2" result="shadowMatrixInner2"
                       values="0 0 0 0 0.968627451 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <filter id="pf0za1q4qd" width="131.7%" height="142.8%" x="-15.8%" y="-19.2%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1"
                       values="0 0 0 0 1 0 0 0 0 0.470588235 0 0 0 0 0 0 0 0 1 0" />
        <feOffset dx="-1" dy="1" in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1" />
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2"
                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter id="adh0koh7if" width="125%" height="133.8%" x="-12.5%" y="-14.6%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1" />
        <feOffset dx="1" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic"
                     result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" result="shadowMatrixInner1"
                       values="0 0 0 0 1 0 0 0 0 0.270588235 0 0 0 0 0 0 0 0 1 0" />
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner2" stdDeviation="1" />
        <feOffset dx="-1" dy="-1" in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite in="shadowOffsetInner2" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic"
                     result="shadowInnerInner2" />
        <feColorMatrix in="shadowInnerInner2" result="shadowMatrixInner2"
                       values="0 0 0 0 0.968627451 0 0 0 0 0.294117647 0 0 0 0 0 0 0 0 1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <path id="3sao0pn29b"
            d="M28.283 2.457l.025.026.027.029c.423.465.795.984 1.12 1.552.324-.568.696-1.087 1.119-1.552l.018-.02.034-.035c3.224-3.276 8.46-3.276 11.683 0 3.158 3.209 3.223 8.684.194 11.985l-.194.204c-3.259 3.311-8.393 4.987-12.53 4.99h-.067l-.086-.005.14.005c-.06 0-.12-.003-.18-.008-.044-.004-.088-.01-.132-.018l-.022.004-.016.002c-.03.005-.062.009-.093.012-.014 0-.027.002-.04.003l-.086.005h-.066.012c-4.14 0-9.281-1.675-12.543-4.99-3.224-3.276-3.224-8.913 0-12.19 3.224-3.275 8.46-3.275 11.683 0zm-9.039 2.687c-1.708 1.736-1.761 4.822-.16 6.642l.16.172c2.064 2.097 5.336 3.397 8.24 3.768l-.041.485c.071-.741.12-1.497.142-2.254-.017-.555-.018-1.113-.003-1.67-.02-.738-.072-1.466-.16-2.172-.277-2.233-.887-3.96-1.806-4.993l.023.022c-1.764-1.791-4.631-1.791-6.395 0zm14.054-.027l.024-.028c-.936 1.03-1.555 2.77-1.835 5.026-.088.707-.14 1.436-.163 2.175.018.553.017 1.108.003 1.66.014.595.048 1.19.096 1.777 2.906-.371 6.178-1.672 8.242-3.769 1.763-1.791 1.763-5.022 0-6.814-1.754-1.782-4.602-1.791-6.367-.027z" />
      <path id="pm861v2qce"
            d="M11.25 44.727c-2.834 0-4.89-1.98-4.996-4.772l-.004-.228V16.601L5 16.602c-2.834 0-4.89-1.98-4.996-4.772L0 11.602v-6.25C0 2.518 1.98.462 4.772.357L5 .352h50c2.834 0 4.89 1.981 4.996 4.772l.004.228v6.25c0 2.834-1.98 4.89-4.772 4.996l-.228.004h-1.25v23.125c0 2.834-1.98 4.89-4.772 4.996l-.228.004h-37.5zm16.874-28.125H9.999L10 39.727c0 .738.317 1.144.963 1.232l.143.014.144.004h16.874V16.602zm21.876 0H31.874v24.375H48.75c.787 0 1.197-.36 1.245-1.097l.005-.153V16.602zm-21.876-12.5H5c-.787 0-1.197.361-1.245 1.098l-.005.152v6.25c0 .787.36 1.197 1.098 1.245l.152.005h23.124v-8.75zm26.876 0H31.874v8.75H55c.787 0 1.197-.36 1.245-1.097l.005-.153v-6.25c0-.84-.41-1.25-1.25-1.25z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g transform="translate(-925 -174) translate(931 179)">
            <use fill="#000" filter="url(#eekivb0cla)" xlinkHref="#3sao0pn29b" />
            <use fill="#FF5656" xlinkHref="#3sao0pn29b" />
            <use fill="#000" filter="url(#scspvc892c)" xlinkHref="#3sao0pn29b" />
          </g>
          <g transform="translate(-925 -174) translate(931 179) translate(0 15.273)">
            <use fill="#000" filter="url(#pf0za1q4qd)" xlinkHref="#pm861v2qce" />
            <use fill="#FBB549" xlinkHref="#pm861v2qce" />
            <use fill="#000" filter="url(#adh0koh7if)" xlinkHref="#pm861v2qce" />
          </g>
        </g>
      </g>
    </g>
  </svg>

export default PromotionPack