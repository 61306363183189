import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEO"
import styled from "@emotion/styled"
import { featuredWrapper, noFeaturedWrapper, color, breakpoints, wrapper1200 } from "../../utils/style"
import Img from "gatsby-image"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import IcomoonIcon from "../../components/shared/IcomoonIcon"
import PromotionPack from "../../icons/svg/PromotionPack"
import Parallax from "../../components/shared/Parallax"
import Subcategories from "./subcategories"

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
  }
  .rich-design {
    position: absolute;
    top: 46%;
    left: 5%;
    margin-top: -35px;
  }
  @media (max-width: ${breakpoints.sm}) {
    .rich-design {
      margin-top: -20px;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper};
  text-align: center;
  margin-top: 100px;
  
  > svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -75px;
  }
  
  .main-title {
    text-transform: uppercase;
    font-size: 44px;
    font-weight: bold;
    margin: 50px auto 105px;
  }
  
  .title-background {
    position: absolute;
    top: -70px;
    font-size: 184px;
    text-transform: uppercase;
    object-fit: contain;
    opacity: 0.08;
    color: #ffffff;
    text-align: center;
    display: block;
    width: 100%;
    font-weight: bold;
    z-index: -5;
  }
  
  @media (max-width: ${breakpoints.lg}) { 
    .title-background {
      width: auto;
      transform: translateX(-50%);
      left: 50%;
      white-space: nowrap;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) { 
    margin-top: 80px;
    
    > svg {
      width: 40px;
      top: -65px;
    }
    .main-title {
      font-size: 24px;
      margin: 50px auto 60px;
    }
    .title-background {
      font-size: 94px;
      top: -42px;
    }
  }  
`

const WrapperDesktop = styled("div")`
  ${wrapper1200};
  overflow-x: hidden;  
  
  .a-center {
    margin-bottom: 50px;
  }
`

const PostContentWrapper = styled("div")`
  margin: 20px 0;
  .item-wrap {
    width: 48%;
    margin-bottom: 50px;
  }
  .item-content-link {
    &:hover, &:active {
      text-decoration: none;
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
  }
  .img-wrap, .h3 {
    margin-bottom: 10px;
    display: block;
  }
  
  .excerpt {
    margin-bottom: 20px;
    max-height: 164px;
    overflow-y: hidden;
    color: ${color.white};
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .item-wrap { width: 100%; }
    .excerpt {
      font-size: 14px;
      max-height: none;
    }
  }
`

const ContentWrapper = styled("div")`
  margin: 30px 0;
  .gatsby-image-wrapper {
    margin: 20px auto;
  }
  p {
    margin: 15px 0;
  }
  a {
    color: ${color.yellow};
  }
  ol {
      list-style: none;
      counter-reset: li;
      margin: 15px 0;
      padding: 0;
      li {
        counter-increment: li;
        margin-left: -1em;
        &::before {
          content: counter(li);
          display: inline-block;
          width: 1em;
          margin-left: -1.5em;
          margin-right: 0.5em;
          text-align: right;
          direction: rtl;
          color: ${color.red};
        }
      }
    }
    ul {
      list-style: none;
      li {
        &::before {
          content: "•";
          color: ${color.red};
          display: inline-block;
          width: 1em;
          margin-right: 5px;
        }
      }
    }
`

const createGroups = (pages, pageLength) =>
  pages
    .map((edge, index) => {
      return index % pageLength === 0
        ? pages.slice(index, index + pageLength)
        : null;
    })
    .filter(item => item);

let nextPageIndex = 1;

const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node
  const metaTitle = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title) ?
    currentCategory.yoast_meta.yoast_wpseo_title : currentCategory.title + "- " + process.env.OWNER
  const metaDesc = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc) ?
    currentCategory.yoast_meta.yoast_wpseo_metadesc : ""
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const submenuLinkTitle = (currentCategory.slug === 'blog') ? 'บทความทั้งหมด' : currentCategory.name;
  const pageLength = pageContext.pageLength;
  const groups = createGroups(data.allWordpressPost.edges, pageLength);
  const [items, setItems] = useState(groups.length > 0 ? groups[0].concat([]) : []);

  const loadMore = (e, index) => {
    nextPageIndex = nextPageIndex + 1;
    setItems(items.concat(groups[index]));
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentCategory.path.replace("category/", "")}
        breadcrumbs={breadcrumbs}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {(currentCategory.acf && currentCategory.acf.category_image) ?
          <FeaturedWrapper>
            <Img fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
                 alt={(currentCategory.acf.category_image.alt_text) ? currentCategory.acf.category_image.alt_text :
                   currentCategory.name} />
            <h1 className="rich-design big-header">
              <span>{currentCategory.name}</span>
            </h1>
          </FeaturedWrapper>
          : <NoFeaturedWrapper>
            <PromotionPack className="pack-image" />
            <h1 dangerouslySetInnerHTML={{ __html: currentCategory.name }} className="main-title" />
            <span className="title-background" dangerouslySetInnerHTML={{ __html: currentCategory.name }} />
          </NoFeaturedWrapper>
        }
        {(currentCategory.path.indexOf('blog')) > 0 &&
          <Subcategories title={submenuLinkTitle}/>
        }

        <ContentWrapper>
          <p>สำหรับโปรโมชั่นในเว็บพนันทั่ว ๆ ไป
            แน่นอนว่าส่วนใหญ่แล้วจะมีความเกี่ยวพันกับการสมัครสมาชิกครั้งแรกของผู้เล่นใหม่ด้วยกันทั้งนั้น ซึ่งใน vrscr888
            ก็เป็นอีกเว็บไซต์คาสิโนออนไลน์ ที่ได้เตรียมโปรโมชั่นใหม่ ๆ ไว้ทุกเดือน
            เพื่อต้อนรับผู้เล่นที่เพิ่งสมัครเข้ามาเป็นสมาชิกกับที่นี่ครั้งแรก
            อีกทั้งยังเป็นส่วนสำคัญที่ช่วยดึงดูดให้ผู้เล่นสนใจที่จะเข้ามาร่วมเล่นในเว็บนี้ได้ง่ายขึ้น</p>
          <p>ในส่วนของ scr888 โปรโมชั่น ที่จะได้รับจากการสมัครสมาชิกกับ vrscr888 นี้
            เรามีสิทธิ์ได้รับโบนัสตั้งแต่การฝากเข้ามาใน Account ครั้งแรกของผู้ใช้งาน เป็นเงินมูลค่าสูงสุด 15,000 บาท
            ให้เราได้ใช้เป็นทุนเล่นในระยะแรก ซึ่งโบนัสจะเพิ่มเปอร์เซ็นต์จากยอดเงินที่เราฝากเข้ามาในตอนแรก
            ยกตัวอย่างเช่นตอนที่สมัครสมาชิกใหม่ ฝากเงินเข้ามาครั้งแรกด้วยจำนวน 1,000 บาท ก็จะได้สิทธิ์รับโบนัสเพิ่มอีก
            100% เป็น 2,000 บาท ได้ทุนเล่นเพิ่มขึ้นอีกเท่าตัวไปแบบง่าย ๆ</p>
          <p>โดยปกติแล้วโบนัสที่ vrscr888 จะมีการปรับเปลี่ยนโปรโมชั่นใหม่อยู่ทุก ๆ เดือน
            ดังนั้นให้ผู้ใช้งานเข้าไปตรวจสอบดูที่หน้า scr888 โปรโมชั่น เพื่อดูรายละเอียดของโปรโมชั่นของเดือนนั้น ๆ
            แต่โดยทั่วไปแล้วโปรโมชั่นแต่ละเดือนนั้นแจกโบนัสเพียบอยู่แล้ว
            นอกจากนั้นไม่ได้เอื้อประโยชน์แค่ผู้เล่นใหม่อย่างเดียวเท่านั้น
            แต่บางโปรโมชั่นยังส่งผลในด้านดีต่อผู้เล่นพนันในเกมต่าง ๆ ของคาสิโนออนไลน์ ไม่ว่าจะเป็นไพ่บาคาร่า สล็อต
            รูเล็ต หรืออาจรวมไปถึงพนันฟุตบอลด้วยก็ได้</p>
        </ContentWrapper>

        <PostContentWrapper className="flex">
          {items.map(({ node }) => (
            <div className="item-wrap" key={node.slug}>

              <Link className="item-content-link" to={"/" + node.slug + "/"}>
                {(node.featured_media && node.featured_media.localFile && node.featured_media.localFile.childImageSharp.fluid) ?
                  <div className="img-wrap">
                    <Img fluid={node.featured_media.localFile.childImageSharp.fluid}
                         alt={node.featured_media.alt_text ? node.featured_media.alt_text : node.title} />
                  </div>
                  : <Img fluid={data.placeholder.childImageSharp.fluid} alt={node.title} />
                }
                <span className="h3">{node.title}</span>
                <div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                <span className="button gradient-button">
                  อ่านเพิ่มเติม
                  <IcomoonIcon icon="arrow-right" color={color.trueBlack} size={18} />
                </span>
              </Link>
            </div>
          ))}
        </PostContentWrapper>


        {(groups.length > 1 && nextPageIndex <= groups.length - 1) &&
        <div className="a-center">
          <button className="button gradient-button" onClick={(e) => loadMore(e, nextPageIndex)}>
            <span>โหลดมากขึ้น...</span>
          </button>
        </div>
        }
      </WrapperDesktop>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
    query($wordpress_id: Int) {
        allWordpressCategory(
            filter: { wordpress_id: {eq: $wordpress_id} }
        ) {
            edges {
                node {
                    id
                    name
                    slug
                    description
                    path
                    link
                    acf {
                        category_image {
                            alt_text
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1400) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
        allWordpressPost(
            sort: { fields:date, order: DESC },
            filter: {
                categories: {
                    elemMatch: {
                        wordpress_id: {eq:$wordpress_id}
                    }
                }
            }
        ) {
            edges {
                node {
                    title
                    excerpt
                    slug
                    featured_media {
                        alt_text
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        placeholder: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
